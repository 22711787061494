import React from 'react'
import PlansCard from './PlansCard'
import Title from '../Title'
import { useStaticQuery, graphql } from 'gatsby'
import styles from '../../css/plans.module.css'

const getPlans = graphql`
    query {
        plannen: allContentfulPlannen(sort: { fields: datum, order: DESC }) {
            edges {
                node {
                    id: contentful_id
                    titel
                    slug
                    datum(formatString: "DD-MM-YYYY")
                    afbeelding {
                        fluid {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
    }
`
const PlansItem = () => {
    const { plannen } = useStaticQuery(getPlans);
    console.log(plannen.edges)
    return (
        <section className={styles.plans}>
            <Title title="Onze" subtitle="plannen" />
            <div className={styles.center}>
                {
                    plannen.edges.map(({ node }) => {
                        return  <PlansCard key={node.id} plans={node} />
                    })
                }
            </div>
        </section>
    )
}

export default PlansItem
