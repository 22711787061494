import React from 'react'
import styles from '../../css/plans-card.module.css'
import Image from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const PlansCard = ({ plans }) => {
    const { slug, titel, afbeelding, datum } = plans
    return (
      <article className={styles.plans}>
        <div className={styles.imgContainer}>
          <Image fluid={afbeelding.fluid} className={styles.img} alt="plannen" />
          <AniLink fade className={styles.link} to={`/plannen/${slug}`}>
            Lees verder
          </AniLink>
          <h6 className={styles.date}>{datum}</h6>
        </div>
        <div className={styles.footer}>
          <h4>{titel}</h4>
        </div>
      </article>
    )
  }

export default PlansCard
